.drop_Down_carousal {
  text-align: end;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 400px) {
  .drop_Down_carousal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heading-section {
    display: flex;
    padding-top: 20px;
    flex-direction: row;
    align-items: center;
    float: left;
    justify-content: flex-start;
  }
  .week-heading {
    display: flex;
    justify-content: center;
  }
  .dropdown_list_carousal {
    width: 147px;
    height: 32px;
  }
}

.week-heading {
  margin-right: 10px;
}
.dropdown_list_carousal {
  background-color: white;
}
#week-heading:focus {
  border: 2px solid black;
  border-radius: 2px;
}
#week-heading:focus-within {
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 17px;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
#week-heading {
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 15px;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 17px;
  cursor: pointer;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
}
.date_panel {
  bottom: 0;
  color: #000000;
  text-align: end;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
  border-top: 1px solid #d5d3d2;
}
.calender_container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 30px;
  padding: 0px 10px;
  bottom: 0;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.selected .week-cover {
  background: #fff;
}

.selected a {
  color: #4085c6;
  text-decoration: underline;
}

.week-cover {
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.hidden {
  display: none;
  visibility: hidden;
}
#exampleCheck1 {
  margin-top: 2px;
}
.align-items-center.item-container {
  margin-top: -8px;
  font-weight: normal;
}
.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card {
  border: 0px !important;
  border-top-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-width: 0px !important;
  border-radius: 10px;
  background-color: #e5e5e5 !important;
  height: 530px;
}
.carousel
  .slider-wrapper.axis-horizontal
  .slider
  li:not(.selected)
  .ca_container
  .week_card
  .calender_container {
  background-color: #e5e5e5;
}

.dropdown_list__value-container {
  font-size: 16px;
  text-align: left;
  font-family: "OpenSans";
}

.dropdown_list__control {
  align-content: center;
  width: 200px;
  font-family: "OpenSans";
}

.dropdown_list__indicators {
  height: auto;
}

.heading-section {
  display: flex;
  align-items: center;
}

.dropdown_list__menu-list {
  text-align: left;
  font-family: "OpenSans";
}

.dropdown_list__menu {
  z-index: 10 !important;
}

.link-section {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 0px;
}
